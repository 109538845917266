import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Loadable from "react-loadable";
import Loader from "../components/Loader/Loader";
import { persistStore } from "redux-persist";
import store from "../store";
import { PersistGate } from "redux-persist/integration/react";

const TeacherDashboard = Loadable({
  loader: () => import("../views/TeacherDashboard"),
  loading: Loader,
});
const Onboarding = Loadable({
  loader: () => import("../views/Onboarding"),
  loading: Loader,
});

const Signup = Loadable({
  loader: () => import("../views/Signup"),
  loading: Loader,
});

const Dashboard = Loadable({
  loader: () => import("../views/Dashboard"),
  loading: Loader,
});

const Leaderboard = Loadable({
  loader: () => import("../views/Leaderboard"),
  loading: Loader,
});

const Collections = Loadable({
  loader: () => import("../views/Collections"),
  loading: Loader,
});
const QuizSection = Loadable({
  loader: () => import("../views/QuizSection"),
  loading: Loader,
});

const Activities = Loadable({
  loader: () => import("../views/Activities"),
  loading: Loader,
});

const NotFound = Loadable({
  loader: () => import("../components/NotFound"),
  loading: Loader,
});

const Assignments = Loadable({
  loader: () => import("../views/GuidedPath"),
  loading: Loader,
});

const News = Loadable({
  loader: () => import("../views/News"),
  loading: Loader,
});

const SpeechTest = Loadable({
  loader: () => import("../views/SpeechTest"),
  loading: Loader,
});

const SpeechTestPassage = Loadable({
  loader: () => import("../views/SpeechTestPassage"),
  loading: Loader,
});

const SpeechTestInstructions = Loadable({
  loader: () => import("../views/SpeechTestInstructions"),
  loading: Loader,
});

class RootRouter extends Component {
  constructor(props) {
    super(props);
    this.persistor = persistStore(store);
  }

  render() {
    return (
      <PersistGate loading={<div>Loading...</div>} persistor={this.persistor}>
        <Router>
          <Switch>
            <Route exact path="/feed/:Type/:Id" component={Dashboard} />
            <Route exact path="/story/:Id" component={Dashboard} />
            <Route exact path="/" component={Dashboard} />
            <Route
              exact
              path="/story"
              component={Dashboard}
              //key={"story-key"}
            />

            <Route path="/leaderboard" component={Leaderboard} />
            <Route exact path="/quiz" component={QuizSection} />
            <Route exact path="/quiz/:Id" component={QuizSection} />
            <Route path="/onboarding" component={Onboarding} />
            <Route path="/get-started" component={Signup} />
            <Route path="/collection/:id" component={Collections} />
            <Route exact path="/activity/:Id" component={Activities} />
            <Route path="/activities" component={Activities} />
            <Route path="/assignments" exact component={Assignments} />
            <Route path="/news" exact component={News} />
            <Route path="/teacher-dashboard" component={TeacherDashboard} />
            <Route path="/speech-test" exact component={SpeechTest} />
            <Route
              path="/speech-test/instructions"
              exact
              component={SpeechTestInstructions}
            />
            <Route
              path="/speech-test/passage"
              exact
              component={SpeechTestPassage}
            />
            <Route component={NotFound} />
          </Switch>
        </Router>
      </PersistGate>
    );
  }
}

export default RootRouter;
