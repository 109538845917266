import * as EVENT from "./eventKeys";

export const generateFilterUrl = (filters) => {
    let mappings = {}, params = [], url = '';
    if (filters && Object.keys(filters)?.length > 0) {
        Object.keys(filters)
            .map(filterKey => {
                if ((filters?.[filterKey] || filters?.[filterKey] === 0))
                    mappings = {...mappings, ...filters}
                else {
                    delete filters[filterKey]
                    mappings = {...filters}
                }
                if (Array.isArray(filters?.[filterKey])) {
                    if (filters?.[filterKey])
                        delete filters[filterKey]
                    mappings = {...filters}
                }
            })
        params = createQueryParamUrl(mappings).join("");
        url = params;
    }
    return url
}
export const createQueryParamUrl = (filterKeys) => {
    let params = []
    try {
        let filterKeysArray = Object.keys(filterKeys)
        filterKeysArray.map(((key, index) => {
            if (filterKeys[key] !== null) {
                params.push(`${key}=${filterKeys[key]}${index !== filterKeysArray?.length - 1 ? '&' : ''}`)
            }
        }));
    } catch (e) {

    }
    return params
}

export const getSourcePage = (path) =>{
    switch (path) {
      case "News":
      case "/news":
        return EVENT.SOURCE_NEWS_FREAD_PAGE;
      case "Activities":
      case "/activities":
        return EVENT.SOURCE_PAGE_ACTIVITIES;
      case "Leaderboard":
      case "/Leaderboard":
        return EVENT.SOURCE_LEADER_BOARD_PAGE;
      case "/collection/:id":
        return EVENT.SOURCE_PAGE_COLLECTION;
      case "Stories":
      case "/story":
        return EVENT.SOURCE_STORY_READING;
      case "Feed":
      case "/":
        return EVENT.SOURCE_FEED_PAGE;
      case "Play":
      case "/quiz":
        return EVENT.SOURCE_PLAY_PAGE;
      case "fLIVE":
      case "/user":
      case "/profile-complete":
      case "/student/profile":
        return EVENT.SOURCE_STUDENT_DASHBOARD_PAGE;
      default:
        return EVENT.SOURCE_FEED_PAGE;
    }
}

export const formatDate = (date) => {
  console.log(date)
  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const dayOfWeek = days[date.getDay()];
  const month = months[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();

  // Format hours and minutes
  const formattedHours = hours % 12 || 12;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const period = hours < 12 ? "AM" : "PM";

  return `${dayOfWeek}, ${month} ${day}, ${year} ${formattedHours}:${formattedMinutes} ${period}`;
};