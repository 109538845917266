import { initialState } from "./state";
import { storage as LocalStorage } from "services/config/storage";

export const linkschoolcode = (state = initialState, action) => {
  switch (action.type) {
    case "SET_SCHOOL_CODE_MODAL":
      return {
        ...state,
        schoolCodeModal: action.data,
      };
    case "SET_SCHOOL_CODE_DATA":
      return {
        ...state,
        ...action.data,
      };
    case "SET_NEXT_GRADE":
      return {
        ...state,
        nextGrade: action.data,
      };
    case "SET_SCHOOL_CLASS_DATA":
      return {
        ...state,
        schoolSectionData: action.data,
      };
    case "SET_LINK_SCHOOL_CODE_MODE":
      return {
        ...state,
        currentMode: action.data,
      };
    case "SET_CMF_ATTEMPT":

      function normalizeDate(date) {
        if (!date) return null; // Handle null or undefined dates
        const normalized = new Date(date);
        normalized.setHours(0, 0, 0, 0); // Set time to midnight
        return normalized;
      }

      const startDate = "2024-04-01";
      const endDate = "2025-03-31";
      const orfStartDate = normalizeDate(
        LocalStorage.fetch.defaultChild()?.school_details?.orf_start_date
      );
      const orfCloseDate = normalizeDate(
        LocalStorage.fetch.defaultChild()?.school_details?.orf_close_date
      );

      let cmfRecords = [];
      let testTaken = false;
      let data = action.data?.forEach((cmfrecord) => {
        let submittedDate = normalizeDate(cmfrecord?.submitted);
        if (
          Date.parse(cmfrecord?.submitted) >= Date.parse(startDate) &&
          Date.parse(cmfrecord?.submitted) <= Date.parse(endDate)
        ) {
          if (
            cmfrecord?.source === "ELG_Web" ||
            cmfrecord?.source === "ELG_app"
          ) {
            cmfRecords.push(cmfrecord);
          }
        }

        const isTodayInRange =
          new Date(submittedDate) >= orfStartDate &&
          new Date(submittedDate) <= orfCloseDate;

        if (isTodayInRange) {
          testTaken = true;
        }
      });
      return {
        ...state,
        cmfAttempt: cmfRecords.length,
        testTaken:testTaken
      };
    default:
      return state;
  }
};
