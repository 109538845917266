import mixpanel from 'mixpanel-browser';
import { storage as LocalStorage } from "services/config/storage";

// mixpanel.init('e795e54edb06e4c8490fefffbdbfb2f4');
mixpanel.init("f2921f2186159beab91bdbf16ae4f1bf", { debug: true });
// let env_check = process.env.NODE_ENV === 'production';
let env_check = true;

let payload = {
  childData: LocalStorage.fetch.childData() ? LocalStorage.fetch.childData().list : '',
  parentData: LocalStorage.fetch.parentData(),
  selectedChild: LocalStorage.fetch.defaultChild()
}

let actions = {
  identify: (id) => {
    if (env_check) mixpanel.identify(id);
  },
  alias: (id) => {
    if (env_check) mixpanel.alias(id);
  },
  track: (name, props) => {
    if (env_check) mixpanel.track(name, props);
  },
  people: {
    set: (props) => {
      if (env_check) mixpanel.people.set(props);
    },
  },
};

export let Mixpanel = actions;
